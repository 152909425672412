import { ModalType, useModal } from "react-modal-hook";
import { useState } from "react";

type HideModal = () => void;

export function useModalWithData<T>(
    component: ModalType
): [(data: T) => void, HideModal, T | undefined] {

    const [modalData, setModalData] = useState<T>();
    const [showModal, hideModal] = useModal(component, [modalData]);

    const customShowModal = (modalData: T) => {
        showModal();
        setModalData(modalData)
    }

    return [customShowModal, hideModal, modalData];
};
