import { LanguageStrings } from './base-strings';

export const fr: LanguageStrings = {
    // common
    'document.title': 'Jean Fortin | Mon espace',
    'topMenu.title': 'Mon espace',
    'en': 'Anglais',
    'fr': 'Français',
    'logout': 'Déconnexion',
    'login': 'Connexion',
    'emptyLine': '',
    'accessDenied': `Accès refusé. Veuillez contacter {email} pour plus d'informations.`,
    'amount': 'Montant',
    'myInformations': 'Mes informations',
    'myCounsellor': 'Mon conseiller',
    'myImportantDates': 'Mes dates importantes',
    'firstCounselling': 'Première Consultation',
    'secondCounselling': 'Deuxième Consultation',
    'nextPayment': 'Prochain Paiement',
    'myProgress': 'Ma progression',
    'myDocuments': 'Mes documents',
    'expectedDischargeDate': 'Date de libération prévue',
    'expectedComplianceDate': 'Date de conformité prévue',
    'uderstandingDebtsTypes': 'Comprendre les types de dettes',
    'howWeCanHelp': 'Comment on peut aider',
    'jfApproach': 'L’approche Jean Fortin',
    'jfAdvantages': 'Voyez les avantages',
    'whoWeHelp': 'Qui on aide',
    'contractors': 'Entrepreneurs',
    'selfEmployed': 'Travailleurs autonomes',
    'retired': 'Retraités',
    'salaried': 'Salariés',
    'betweenTwoJobs': 'Entre deux emplois',
    'students': 'Etudiants',
    'consolidation': 'La consolidation',
    'consumerProposal': 'La proposition du consommateur',
    'bankruptcy': 'La faillite',
    'toolsAndResources': 'Outils et ressources',
    'finincialTracking': 'Suivez de près votre santé financière',
    'makeReview': 'Faites votre bilan',
    'calculateDebtRatio': `Calculez votre ratio d'endettement`,
    'makeOnlineBudget': 'Faites votre budget en ligne',
    'calHowMuchBorrow': 'Calculez combien vous pourriez emprunter',
    'understandCostOfCreditCard': 'Comprenez le coût des cartes de crédit',
    'understandYourCreditReport': 'Comprenez votre dossier de crédit',
    'guideToDownload': 'Guides à télécharger',
    'glossary': 'Glossaire',
    'advicesAndFolders': 'Conseils et dossiers',
    'accessClientFolder': 'Accéder à votre dossier client',
    'aboutJF': 'À propos de Jean Fortin',
    'careers': 'Carrières',
    'media': 'Médias',
    'makeAppointment': 'Prenez rendez-vous',
    'followUsOn': 'Suivez-nous sur',
    'workingDays': 'Lundi au vendredi',
    'workingHours': '8h00 à 17h00',
    'findAnOffice': 'Trouvez un bureau près de vous',
    'PrivacyPolicyTitle': 'Politique de confidentialité',
    'siteMap': 'Carte du site ',
    'editMyInformations': 'Modifier mes informations',
    'backTo': 'retour à',
    'backToUrl': 'https://jeanfortin.com/',
    'PrivacyPolicyUrl': 'https://jeanfortin.com/politique-de-vie-privee',
    'download': 'Télécharger',
    'fileSize': '{size} Ko',
    'dropzoneMessage': 'Cliquez ou déposez des fichiers ici pour télécharger{br} fichiers supportés: (.txt, .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png)',
    'delete': 'Supprimer',
    'deleteDocumentConfirmMessage': 'Êtes-vous sûr de vouloir supprimer le document "{fileName}" ?',
    'yes': 'Oui',
    'no': 'Non',
    'confirmation': 'Confirmation',
    'deleteDocumentSuccessMessage': 'Le document "{fileName}" a été supprimé avec succès.',
    'uploadDocumentsSuccessMessage': 'Les documents ont été téléchargés avec succès.',
    'uploadDocumentsFailedMessage': 'Erreur lors du téléchargement des documents.',
    'uploadDocumentsFailedMessageSizeNotAllowed': 'La taille de certains fichiers dépasse la taille autorisée.',
    'uploadDocumentsFailedMessageExtensionNotAllowed': 'Certains fichiers ont des extensions non autorisées.',
    'totalAmountRemaining': `Balance à payer`,
    'postponementPaymentRequest': 'Demande de report de paiement',
    'requestedPostponeDate': 'Date de paiement souhaitée',
    'comment': 'Commentaire',
    'requestPostponement': 'Demander un report',
    'cancel': 'Annuler',
    'submit': 'Soumettre',
    'postponementPaymentRequestSuccessMessage': 'Votre demande de report de paiement a été soumise avec succès.',
    'postponementPaymentConfirmationNumber': 'Numéro de demande',
    'nextPaymentDate': 'Date de prochain paiement',
    'postponementRequest': 'Demande de report',
    'postponementPaymentRequest.Pending': `Le statut de votre demande de modification de paiement est: En attente.`,
    'postponementPaymentRequest.Processing': `Le statut de votre demande de modification de paiement est: En traitement.`,
    'postponementPaymentRequest.Accepted': `Votre demande de modification de paiement a été accepté.`,
    'postponementPaymentRequest.Refused': `Votre demande de modification de paiement a été refusé. Veuillez contacter notre département de comptabilité via courriel au ppa@jeanfortin.com ou par téléphone au 450-442-5015 poste 2204.`,
};
