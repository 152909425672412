import React, { FunctionComponent } from 'react';
import { Container, Segment, List, Divider } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {}

export const Footer: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    const currentYear = new Date().getFullYear(); // Get the current year

    return (<>
        <Segment inverted vertical className="layout-footer">
            <Divider inverted section className="footer-divider" />
            <Container textAlign='center'>
                <List horizontal inverted link divided size="tiny" className="copy-right" >
                    <List.Item>&copy; {currentYear} Jean Fortin &amp; Associes</List.Item>
                    <List.Item>-</List.Item>
                    <List.Item as="a" href={intl.formatMessage({ id: "PrivacyPolicyUrl", defaultMessage: "https://jeanfortin.com/politique-de-vie-privee" })}>
                        {intl.formatMessage({ id: "PrivacyPolicyTitle" })}
                    </List.Item>
                    <List.Item><FormattedMessage id="backTo" /></List.Item>
                    <List.Item as="a" href={intl.formatMessage({ id: "backToUrl", defaultMessage: "https://jeanfortin.com/" })}>
                        {'jeanfortin.com'}
                    </List.Item>
                </List>
            </Container>

        </Segment>
    </>
    );
};
