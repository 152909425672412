import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

export interface CustomDatePickerProps extends ReactDatePickerProps {
    onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined, data?: CustomDatePickerProps) => void;
    fluid?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props: CustomDatePickerProps) => {
    const dateFormat = props.dateFormat || (props.showTimeSelect ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd");

    return (
        <DatePicker {...props}
            onChange={(date, event) => props.onChange(date, event, props)}
            dateFormat={dateFormat}
            wrapperClassName={props.fluid ? "stretchedDatepicker" : ""}
        />
    );
};

export default CustomDatePicker;