const baseStrings = {
    // common
    'document.title': 'Jean Fortin | My space',
    'topMenu.title': 'My space',
    'en': 'English',
    'fr': 'French',
    'logout': 'Logout',
    'login': 'Login',
    'emptyLine': '',
    'accessDenied': 'Access denied. Please contact {email} for more infos.',
    'amount': 'Amount',
    'myInformations': 'My Informations',
    'myCounsellor': 'My Counsellor',
    'myImportantDates': 'My Important Dates',
    'firstCounselling': 'First Counselling',
    'secondCounselling': 'Second Counselling',
    'nextPayment': 'Next Payment',
    'myProgress': 'My Progress',
    'myDocuments': 'My Documents',
    'expectedDischargeDate': 'Expected Discharge Date',
    'expectedComplianceDate': 'Expected Compliance Date',
    'uderstandingDebtsTypes': 'Understanding types of debts',
    'howWeCanHelp': 'How we can help',
    'jfApproach': 'Jean Fortin Approach',
    'jfAdvantages': 'See The Advantages',
    'whoWeHelp': 'Who We Help',
    'contractors': 'Contractors',
    'selfEmployed': 'Self-employed',
    'retired': 'Retired',
    'salaried': 'Salaried',
    'betweenTwoJobs': 'Between two jobs',
    'students': 'Students',
    'consolidation': 'The Consolidation',
    'consumerProposal': 'The consumer proposal',
    'bankruptcy': 'Bankruptcy',
    'toolsAndResources': 'Tools and resources',
    'finincialTracking': 'Keep track of your financial health',
    'makeReview': 'Make your review',
    'calculateDebtRatio': 'Calculate your debt ratio',
    'makeOnlineBudget': 'Make your budget online',
    'calHowMuchBorrow': 'Calculate how much you could borrow',
    'understandCostOfCreditCard': 'Understand the cost of credit cards',
    'understandYourCreditReport': 'Understand your credit report',
    'guideToDownload': 'Guide to download',
    'glossary': 'Glossary',
    'advicesAndFolders': 'Advices and folders',
    'accessClientFolder': 'Access to your client folder',
    'aboutJF': 'About Jean Fortin',
    'careers': 'Carresrs',
    'media': 'Media',
    'makeAppointment': 'Make an appointment',
    'followUsOn': 'Follow us on',
    'workingDays': 'Monday to Friday',
    'workingHours': '8h00 to 17h00',
    'findAnOffice': 'Find an office near you',
    'siteMap': 'Site Map ',
    'editMyInformations': 'Edit my informations',
    'backTo': 'back to',
    'backToUrl': 'https://jeanfortin.com/en/',
    "PrivacyPolicyTitle": "Privacy Policy",
    'PrivacyPolicyUrl': 'https://jeanfortin.com/en/privacy-policy/',
    'download': 'Download',
    'fileSize': '{size} KB',
    'dropzoneMessage': 'Click or drop files here to upload{br} supported files : (.txt, .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png)',
    'delete': 'Delete',
    'deleteDocumentConfirmMessage': 'Are you sure you want to delete document "{fileName}" ?',
    'yes': 'Yes',
    'no': 'No',
    'confirmation': 'Confirmation',
    'deleteDocumentSuccessMessage': 'The document "{fileName}" has been deleted successfully.',
    'uploadDocumentsSuccessMessage': 'The documents have been uploaded successfully.',
    'uploadDocumentsFailedMessage': 'Error while uploading documents.',
    'uploadDocumentsFailedMessageSizeNotAllowed': 'Some files exceed  the size limit.',
    'uploadDocumentsFailedMessageExtensionNotAllowed': 'Some files has unaccepted extensions.',
    'totalAmountRemaining': 'Total Amount Remaining',
    'postponementPaymentRequest': 'Postponement Payment Request',
    'requestedPostponeDate': 'Desired payment date',
    'comment': 'Comment',
    'requestPostponement': 'Request a postponement',
    'cancel': 'Cancel',
    'submit': 'Submit',
    'postponementPaymentRequestSuccessMessage': 'Your postponement payment request has been submitted successfully.',
    'postponementPaymentConfirmationNumber': 'Request number',
    'nextPaymentDate': 'Next Payment Date',
    'postponementRequest': 'Postponement Request',
    'postponementPaymentRequest.Pending': `The status of your payment modification request is: Pending.`,
    'postponementPaymentRequest.Processing': `The status of your payment modification request is: Processing.`,
    'postponementPaymentRequest.Accepted': `Your payment modification request has been accepted.`,
    'postponementPaymentRequest.Refused': `Your payment modification request has been refused. Please contact our accounting department via email at ppa@jeanfortin.com or by phone at 450-442-5015 ext. 2204.`,
};
export type LanguageStrings = typeof baseStrings;
export const en = baseStrings;