import React, { useEffect } from 'react'
import { Loader } from 'semantic-ui-react';
import { getErrorMessages } from 'utils/Utils';
import { useToasts } from 'react-toast-notifications';
interface Props {

}

const MobileLoginRedirect: React.FC<Props> = (props: Props) => {
    const { addToast } = useToasts();

    useEffect(() => {
        (async function () {
            try {
                const searchParams = new URLSearchParams(decodeURIComponent(window.location.search));

                const response = await fetch("/signin-oidc", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded"
                    },
                    body: searchParams
                });

                const redirectUrl = response.redirected ? response.url : "/";
                window.location.href = redirectUrl;
            } catch (error) {
                const errorMsg = getErrorMessages(error.response);
                addToast(errorMsg, { appearance: "error", autoDismiss: true });
            } finally {
            }
        })();
    }, [])

    return (<Loader inline />)
}

export default MobileLoginRedirect;
